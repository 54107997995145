import React, { Fragment } from 'react';
import Plx from 'react-plx';
import Logo from '../Logo';
import headerStyle from './header-style';
import Links from '../Links';
import styled from 'styled-components';
import Bitmoji from '../Bitmoji';

const LogoFirefox = styled.div`
@-moz-document url-prefix() {
    width: 9vw;
`;

const parallaxDataLogo = [
  {
    start: 0,
    end: 150,
    properties: [
      {
        startValue: 0,
        endValue: 1,
        property: 'opacity',
      },
    ],
  },
];

const EmojiLogoStyle = styled.div`
display: flex;
align-items: center;

  }
`;
const LinksStyle = styled.div`
  @media (max-width: 1000px) {
    display: none;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: none;
  }
`;

const Header = () => {
  return (
    <Fragment>
      <Plx className="MyAwesomeParallax" parallaxData={parallaxDataLogo}>
        <div style={headerStyle}>
          <EmojiLogoStyle>
            <Bitmoji height="75vh" />
            <LogoFirefox>
              <Logo logoColor="#eee" height="9vh" />
            </LogoFirefox>
          </EmojiLogoStyle>
          <LinksStyle>
            <Links />
          </LinksStyle>
        </div>
      </Plx>
    </Fragment>
  );
};
export default Header;
