// import 'babel-polyfill';
// import 'react-app-polyfill';

// import 'core-js/es6/map';
// import 'core-js/es6/set';

import React, { Fragment, Component } from 'react';
import ReactDOM from 'react-dom';
import Plx from 'react-plx';
import Logo from './components/Logo';
import Header from './components/Header/Header';
import NavAndContent from './components/NavAndContent';
import SkillsHeader from './components/SkillsHeader';
import SkillsList from './components/SkillsList';
import Bitmoji from './components/Bitmoji';
import Footer from './components/Footer';
import styled from 'styled-components';

const LogoFirefox = styled.div`
@-moz-document url-prefix() {
    width: 25vw;
`;

const BitmojiAndLogo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const logoLandingStyle = {
  display: 'flex',
  justifyContent: 'center',
  marginTop: '2rem',
};

const headerSticky = {
  position: 'fixed',
  top: 0,
  zIndex: 100,
};

const logoParallaxData = [
  {
    start: 0,
    end: 150,
    properties: [
      {
        startValue: 1,
        endValue: 0.1,
        property: 'scale',
      },
      {
        startValue: 1,
        endValue: 0,
        property: 'opacity',
      },
    ],
  },
];

const skillsParallaxData = [
  {
    start: 0,
    end: 990,
    properties: [
      {
        startValue: 1,
        endValue: 0,
        property: 'opacity',
      },
    ],
  },
];

const navAndContentParallaxData = [
  {
    start: 0,
    end: 500,
    properties: [
      {
        startValue: 0,
        endValue: 1,
        property: 'opacity',
      },
    ],
  },
];

class App extends Component {
  render() {
    return (
      <Fragment>
        <div style={headerSticky}>
          <Header />
        </div>

        <div style={logoLandingStyle}>
          <Plx className="MyAwesomeParallax" parallaxData={logoParallaxData}>
            <BitmojiAndLogo>
              <Bitmoji width="120px" height="120px" />
              <LogoFirefox>
                <Logo logoColor="#eee" width="30vw" />
              </LogoFirefox>
            </BitmojiAndLogo>
          </Plx>
        </div>

        <Plx className="MyAwesomeParallax" parallaxData={skillsParallaxData}>
          <SkillsHeader />
          <SkillsList />
        </Plx>
        <Plx className="MyAwesomeParallax" parallaxData={navAndContentParallaxData}>
          <NavAndContent />
        </Plx>
        <div>
          <Footer />
        </div>
      </Fragment>
    );
  }
}

ReactDOM.render(<App />, document.getElementById('root'));
