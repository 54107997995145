// @ts-nocheck
import React from 'react';
import styled from 'styled-components';

const SkillsHeaderStyle = styled.h1`
  width: 100%;
  margin: 2rem auto 7rem auto;
  padding: 1rem 0;
  font-size: 1.7rem;
  font-family: 'Source Code Pro';
  font-weight: 500;
  letter-spacing: 2px;
  line-height: 1.8em;
  color: #fff;
  text-shadow: 1px 1px 0px #909090;
  text-align: center;
  text-transform: uppercase;
  background-color: rgb(122, 123, 125, 0.1);
  //border-radius: 3px;

  @media (max-width: 600px) {
    font-size: 1rem;
  }
`;

function SkillsHeader() {
  return (
    <div>
      <SkillsHeaderStyle>Web Developer, Photographer/Videographer</SkillsHeaderStyle>
    </div>
  );
}

export default SkillsHeader;
