// @ts-nocheck
import React from 'react';
import styled from 'styled-components';

const SkillsCardStyle = styled.h1`
  min-width: 240px;
  margin: 1rem 2rem;
  padding: 0.8rem 5vw;
  font-size: 1.4rem;
  font-family: 'Muli', monospace;
  line-height: 1.6em;
  color: #fff;
  text-shadow: 1px 1px 0px #a9a9a9;
  text-align: center;
  text-transform: uppercase;
  background-color: rgb(122, 123, 125, 0.1);
  border-radius: 3px;
  box-shadow: 1px 1px 5px #909090;
`;

function SkillsCard(props) {
  return (
    <div>
      <SkillsCardStyle>
        <ul>
          <li>{props.skill1}</li>
          <li>{props.skill2}</li>
          <li>{props.skill3}</li>
          <li>{props.skill4}</li>
          <li>{props.skill5}</li>
        </ul>
      </SkillsCardStyle>
    </div>
  );
}

export default SkillsCard;
