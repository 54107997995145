const headerStyle = {
  // position: '-webkit-sticky',
  // position: 'sticky',
  width: '100vw',
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  flexwrap: 'wrap',
  // padding: '.6rem',
  backgroundColor: 'rgba(121,169,209, .7)',
  boxShadow: '1px 1px 125px 1px #83B3DB',
};

export default headerStyle;
