import Links from './Links';
import { Paper } from './';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  root: {
    position: 'relative',
    width: '100%',
    padding: '0 0 1.5rem 0',
    margin: '8rem 0 0 0',
    fontFamily: 'Muli',
    fontSize: '.8em',
    backgroundColor: '#79A9D1',
    textAlign: 'center',
    color: '#eee',
    boxShadow: 'none',
  },
};

const Footer = props => {
  const { classes } = props;

  return (
    <Fragment>
      <Paper className={classes.root}>
        <Links />
        <p style={{ margin: '0 0 5rem 0' }} />
        &copy;2019 P. KEVIN O'LEARY
      </Paper>
    </Fragment>
  );
};

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Footer);
