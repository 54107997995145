import React from 'react';
import ImageGallery from './ImageGallery';
import '../css/carousel-gallery.css';
import '../css/app-carousel-gallery.css';
const PREFIX_URL = 'httpss://raw.githubusercontent.com/xiaolin/react-image-gallery/master/static/';

export default class VideoGallery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showIndex: false,
      showBullets: false,
      infinite: true,
      showThumbnails: true,
      showFullscreenButton: true,
      showGalleryFullscreenButton: true,
      showPlayButton: false,
      showGalleryPlayButton: false,
      showNav: true,
      isRTL: false,
      // slideDuration: 450,
      // slideInterval: 2000,
      thumbnailPosition: 'bottom',
      showVideo: {},
    };

    const videoStyle = {
      height: '550px',
    };

    this.images = [
      {
        thumbnail: `https://kevinoleary.net/img/taj-weekes-01.jpg`,
        original: `https://kevinoleary.net/img/taj-weekes-01.jpg`,
        embedUrl: 'https://www.youtube.com/embed/zTHQQev6Erg?start=27&autoplay=1',
        description: 'Music Video - Director, Videographer, Editor',
        renderItem: this._renderVideo.bind(this),
      },
      {
        thumbnail: `https://kevinoleary.net/img/art-of-the-croissant-01.jpg`,
        original: `https://kevinoleary.net/img/art-of-the-croissant-01.jpg`,
        embedUrl: 'https://www.youtube.com/embed/lCZl1-7FAFg?autoplay=1',
        description: 'The Art of the Croissant - Director, Videographer, Editor',
        renderItem: this._renderVideo.bind(this),
      },
      {
        thumbnail: `https://kevinoleary.net/img/ny-foodfilm-fest-breads-bakery-01.jpg`,
        original: `https://kevinoleary.net/img/ny-foodfilm-fest-breads-bakery-01.jpg`,
        embedUrl: 'https://www.youtube.com/embed/8CUayc4jL1Q?start=4&autoplay=1',
        description: 'NY Food Film Fest - Director, Videographer, Editor',
        renderItem: this._renderVideo.bind(this),
      },
      {
        thumbnail: `https://kevinoleary.net/img/reckoning-bradstock-01.jpg`,
        original: `https://kevinoleary.net/img/reckoning-bradstock-01.jpg`,
        embedUrl: 'https://www.youtube.com/embed/p0ixPfM2ZAU?autoplay=1',
        description: 'Concert Documentary - Director, Videographer, Editor',
        renderItem: this._renderVideo.bind(this),
      },
    ];
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.slideInterval !== prevState.slideInterval ||
      this.state.slideDuration !== prevState.slideDuration
    ) {
      // refresh setInterval
      this._imageGallery.pause();
      this._imageGallery.play();
    }
  }

  _onImageClick(event) {
    console.debug(
      'clicked on image',
      event.target,
      'at index',
      this._imageGallery.getCurrentIndex(),
    );
  }

  _onImageLoad(event) {
    console.debug('loaded image', event.target.src);
  }

  _onSlide(index) {
    this._resetVideo();
    console.debug('slid to index', index);
  }

  _onPause(index) {
    console.debug('paused on index', index);
  }

  _onScreenChange(fullScreenElement) {
    console.debug('isFullScreen?', !!fullScreenElement);
  }

  _onPlay(index) {
    console.debug('playing from index', index);
  }

  _handleInputChange(state, event) {
    this.setState({ [state]: event.target.value });
  }

  _handleCheckboxChange(state, event) {
    this.setState({ [state]: event.target.checked });
  }

  _handleThumbnailPositionChange(event) {
    this.setState({ thumbnailPosition: event.target.value });
  }

  _getStaticImages() {
    let images = [];
    for (let i = 2; i < 12; i++) {
      images.push({
        original: `${PREFIX_URL}${i}.jpg`,
        thumbnail: `${PREFIX_URL}${i}t.jpg`,
      });
    }

    return images;
  }

  _resetVideo() {
    this.setState({ showVideo: {} });

    if (this.state.showPlayButton) {
      this.setState({ showGalleryPlayButton: true });
    }

    if (this.state.showFullscreenButton) {
      this.setState({ showGalleryFullscreenButton: true });
    }
  }

  _toggleShowVideo(url) {
    this.state.showVideo[url] = !Boolean(this.state.showVideo[url]);
    this.setState({
      showVideo: this.state.showVideo,
    });

    if (this.state.showVideo[url]) {
      if (this.state.showPlayButton) {
        this.setState({ showGalleryPlayButton: false });
      }

      if (this.state.showFullscreenButton) {
        this.setState({ showGalleryFullscreenButton: false });
      }
    }
  }

  _renderVideo(item) {
    return (
      <div className="image-gallery-image">
        {this.state.showVideo[item.embedUrl] ? (
          <div className="video-wrapper">
            <a className="close-video" onClick={this._toggleShowVideo.bind(this, item.embedUrl)} />
            <iframe
              width="560"
              height="315"
              src={item.embedUrl}
              frameBorder="0"
              allowFullScreen
              title="video"
            />
          </div>
        ) : (
          <a onClick={this._toggleShowVideo.bind(this, item.embedUrl)}>
            <div className="play-button" />
            <img src={item.original} alt="video" />
            {item.description && (
              <span className="image-gallery-description" style={{ right: '0', left: 'initial' }}>
                {item.description}
              </span>
            )}
          </a>
        )}
      </div>
    );
  }

  render() {
    return (
      <section className="app">
        <ImageGallery
          ref={i => (this._imageGallery = i)}
          items={this.images}
          lazyLoad={true}
          onClick={this._onImageClick.bind(this)}
          onImageLoad={this._onImageLoad}
          onSlide={this._onSlide.bind(this)}
          onPause={this._onPause.bind(this)}
          onScreenChange={this._onScreenChange.bind(this)}
          onPlay={this._onPlay.bind(this)}
          infinite={this.state.infinite}
          showBullets={this.state.showBullets}
          showFullscreenButton={
            this.state.showFullscreenButton && this.state.showGalleryFullscreenButton
          }
          showPlayButton={this.state.showPlayButton && this.state.showGalleryPlayButton}
          showThumbnails={this.state.showThumbnails}
          showIndex={this.state.showIndex}
          showNav={this.state.showNav}
          isRTL={this.state.isRTL}
          thumbnailPosition={this.state.thumbnailPosition}
          slideDuration={parseInt(this.state.slideDuration)}
          slideInterval={parseInt(this.state.slideInterval)}
          additionalClass="app-image-gallery"
        />
      </section>
    );
  }
}
