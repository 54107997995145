import React from 'react';

const InstagramIcon = () => {
  const imageStyle = {
    minWidth: '5vh',
    height: '67px',
    margin: '0 1.8vw 0 0',
  };

  return (
    <a href="https://www.instagram.com/p.kevinoleary" title="Instagram" target="_blank" rel="noopener noreferrer">
    <svg style={imageStyle} viewBox="0 0 32 32">
      
        <g transform="scale(1)">
          <g>
            <path
              d="M16,0C7.163,0,0,7.163,0,16c0,8.836,7.163,16,16,16s16-7.164,16-16C32,7.163,24.837,0,16,0z"
              fill="rgba(9, 100, 100, 0.4)"
            />
          </g>
          <path
            d="M22.057,7.93H9.943c-1.14,0-2.019,0.879-2.019,2.019v12.113c0,1.14,0.879,2.019,2.019,2.019h12.113   c1.14,0,2.019-0.879,2.019-2.019V9.949C24.076,8.808,23.196,7.93,22.057,7.93z M16.012,12.833c1.791,0,3.243,1.407,3.243,3.142   c0,1.735-1.452,3.142-3.243,3.142c-1.79,0-3.242-1.408-3.242-3.142C12.77,14.239,14.222,12.833,16.012,12.833z M22.057,21.557   c0,0.354-0.151,0.505-0.505,0.505H10.448c-0.353,0-0.505-0.151-0.505-0.505v-7.066l1.258,0.274   c-0.135,0.439-0.208,0.903-0.208,1.385c0,2.684,2.248,4.863,5.018,4.863c2.772,0,5.019-2.178,5.019-4.863   c0-0.482-0.073-0.946-0.208-1.385l1.234-0.274V21.557z M22.057,12.472c0,0.279-0.226,0.505-0.505,0.505h-2.019   c-0.279,0-0.505-0.226-0.505-0.505v-2.019c0-0.279,0.226-0.505,0.505-0.505h2.019c0.279,0,0.505,0.226,0.505,0.505V12.472z"
            fill="#FFFFFF"
          />
        </g>
    </svg>
    </a>
  );
};

export default InstagramIcon;
