import React from 'react';
import EmailIcon from './icons/EmailIcon';
import InstagramIcon from './icons/InstagramIcon';
import LinkedinIcon from './icons/LinkedinIcon';
import VimeoIcon from './icons/VimeoIcon';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  linkContainer: {
    display: 'flex',
    flex: '1',
    flexWrap: 'wrap',
    justifyContent: 'center',
    opacity: '.80',
  },
});

function IconButtons(props) {
  const { classes } = props;
  return (
    <div className={classes.linkContainer}>
      <EmailIcon />
      <LinkedinIcon />
      <InstagramIcon />
      <VimeoIcon />
    </div>
  );
}

IconButtons.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(IconButtons);
