import React from 'react';
import styled from 'styled-components';
import SkillsCard from './SkillsCard'

const SkillsStyle = styled.h1`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

export default function SkillsList() {
  return (
    <SkillsStyle>
      <SkillsCard
        skill1="HTML5"
        skill2="CSS/Flexbox/Grid"
        skill3="Javascript"
        skill4="React/ES6"
        skill5="Bootstrap"
      />
      <SkillsCard
        skill1="Photoshop"
        skill2="Final Cut Pro"
        skill3="Davinci  Resolve"
        skill4="After Effects"
        skill5="Motion"
      />
    </SkillsStyle>
  );
}
