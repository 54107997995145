import { CodeGallery, Paper, PhotoGallery, Tab, Tabs, Typography, VideoGallery } from './';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const theme = createMuiTheme({
  typography: {
    fontSize: 28,
    fontFamily: 'Poppins',
  },
});

const styles = {
  root: {
    backgroundColor: '#79A9D1',
    margin: '3rem 0 0 0',
    padding: '0',
    width: '100%',
    boxShadow: 'none',
  },
  tabsRoot: {
    display: 'flex',
    justifyContent: 'center',
    margin: '.3rem 0em .2em 0em',
    padding: '0 5% 0 5%',
  },
  tabText: {
    color: '#F7F1E3',
    outline: '0',
  },
  tabIndicator: {
    backgroundColor: '#ddd !important',
    outline: '0',
  },
};

function TabContainer(props) {
  return (
    <Typography component={'span'} variant={'body2'}>
      {props.children}
    </Typography>
  );
}

class NavAndContent extends Component {
  state = {
    value: 0,
  };

  handleChange = (event, value) => {
    this.setState({
      value,
      checked: !this.state.checked,
    });
  };

  render() {
    const { classes } = this.props;
    const { value } = this.state;

    return (
      <Paper elevation={1} className={classes.root}>
        <MuiThemeProvider theme={theme}>
          <Typography component={'span'} variant={'body2'}>
            <Tabs
              value={this.state.value}
              onChange={this.handleChange}
              className={classes.tabText}
              classes={{
                root: classes.tabsRoot,
                indicator: classes.tabIndicator,
              }}
            >
              <Tab label="Code" />
              <Tab label="Photo" />
              <Tab label="Video" />
              {/* <Tab label="Drums" /> */}
            </Tabs>
          </Typography>
        </MuiThemeProvider>

        {value === 0 && (
          <TabContainer>
            <CodeGallery />
          </TabContainer>
        )}
        {value === 1 && (
          <TabContainer>
            <PhotoGallery />
          </TabContainer>
        )}
        {value === 2 && (
          <TabContainer>
            <VideoGallery />
          </TabContainer>
        )}
        {/* {value === 3 && (
          <TabContainer>
            <Drums />
          </TabContainer>
        )} */}
      </Paper>
    );
  }
}

NavAndContent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NavAndContent);
