import React from 'react';
import BitmojiIcon from '../img/kev-bitmoji-02.png';
import styled from 'styled-components';

const BitmojiStyle = styled.div`
  display: flex;
  transform: scale(1);
  background-color: rgba(200, 200, 200, 0.2);
  border: 2px solid #ededed;
  border-radius: 50%;
  margin: 1rem 0.9rem;
  overflow: hidden;

  @media (max-width: 730px) {
    // width: 15vw;
    // height: 15vw;
  }
`;

export default function Bitmoji(props) {
  return (
    <BitmojiStyle>
      <img width={props.width} height={props.height} src={BitmojiIcon} alt="Kevin's Bitmoji" />
    </BitmojiStyle>
  );
}
