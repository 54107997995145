import React from 'react';

const VimeoIcon = () => {
  const imageStyle = {
    minWidth: '5vh',
    height: '67px',
    margin: '0 0 0 0',
  };

  return (
     <a href="https://vimeo.com/album/2280963" title="Vimeo" target="_blank" rel="noopener noreferrer">
    <svg style={imageStyle} viewBox="0 0 32 32">
        <g transform="scale(1)">
          <g>
            <path
              d="M16,0C7.163,0,0,7.163,0,16c0,8.836,7.163,16,16,16s16-7.164,16-16C32,7.163,24.837,0,16,0z"
              fill="rgba(9, 100, 100, 0.4)"
            />
          </g>
          <path
            d="M7.659,14.357c0,0,1.521-1.199,2.028-0.6c0.507,0.6,2.442,7.836,3.088,9.171   c0.564,1.171,2.119,2.718,3.824,1.613c1.705-1.105,7.373-5.945,8.388-11.66c1.014-5.714-6.821-4.517-7.651,0.461   c2.075-1.245,3.182,0.506,2.12,2.489c-1.06,1.981-2.028,3.273-2.535,3.273c-0.506,0-0.895-1.326-1.475-3.643   c-0.599-2.395-0.596-6.71-3.087-6.221c-2.35,0.461-5.438,4.149-5.438,4.149L7.659,14.357z"
            fill="#FFFFFF"
          />
        </g>
    </svg>
     </a>
  );
};

export default VimeoIcon;
