import React from 'react';

const LinkedInIcon = () => {
  const imageStyle = {
    minWidth: '5vh',
    height: '67px',
    margin: '0 1.8vw 0 0',
  };

  return (
     <a
        href="https://www.linkedin.com/in/p-kevin-o-leary"
        title="LinkedIn"
        target="_blank"
        rel="noopener noreferrer"
      >
    <svg style={imageStyle} viewBox="0 0 32 32">
        <g transform="scale(1)">
          <g>
            <path
              d="M16,0C7.163,0,0,7.163,0,16c0,8.836,7.163,16,16,16s16-7.164,16-16C32,7.163,24.837,0,16,0z"
              fill="rgba(9, 100, 100, 0.4)"
            />
          </g>
          <path
            d="M24.294,22.942v-6.137c0-3.288-1.755-4.818-4.096-4.818c-1.889,0-2.735,1.039-3.206,1.768v-1.517h-3.558   c0.047,1.005,0,10.704,0,10.704h3.558v-5.978c0-0.319,0.023-0.639,0.117-0.867c0.257-0.639,0.842-1.301,1.825-1.301   c1.288,0,1.803,0.981,1.803,2.42v5.727L24.294,22.942L24.294,22.942z M9.685,10.777c1.24,0,2.013-0.823,2.013-1.85   c-0.023-1.05-0.773-1.849-1.99-1.849S7.696,7.877,7.696,8.927c0,1.028,0.772,1.85,1.967,1.85H9.685z M11.464,22.942V12.238H7.907   v10.704H11.464z"
            fill="#FFFFFF"
          />
        </g>
     
    </svg>
     </a>
  );
};

export default LinkedInIcon;
