import React from 'react';

function Logo(props) {
  return (
    <svg width={props.width} height={props.height} viewBox="0 0 169 97">
      <g transform="scale(1)">
        <path
          fill={props.logoColor}
          d="M6.665,12.534v5.237h0.107c0.783-1.924,2.057-3.411,3.82-4.462c1.764-1.05,3.768-1.576,6.013-1.576
	c2.101,0,3.927,0.392,5.477,1.175c1.55,0.784,2.84,1.844,3.874,3.18c1.033,1.336,1.799,2.885,2.298,4.649
	c0.499,1.763,0.749,3.626,0.749,5.585s-0.25,3.82-0.749,5.584c-0.499,1.763-1.265,3.313-2.298,4.649
	c-1.034,1.336-2.324,2.387-3.874,3.153c-1.55,0.765-3.376,1.149-5.477,1.149c-0.998,0-1.996-0.125-2.993-0.374
	c-0.998-0.25-1.924-0.623-2.779-1.122s-1.612-1.123-2.271-1.871c-0.66-0.748-1.167-1.621-1.523-2.618H6.932v15.39H3.566V12.534
	H6.665z M25.128,22.073c-0.339-1.407-0.874-2.663-1.603-3.768c-0.731-1.104-1.666-2.003-2.806-2.698
	c-1.14-0.695-2.511-1.042-4.114-1.042c-1.853,0-3.403,0.321-4.65,0.962c-1.247,0.641-2.244,1.496-2.993,2.565
	c-0.748,1.068-1.273,2.316-1.576,3.74c-0.303,1.426-0.454,2.922-0.454,4.49c0,1.425,0.169,2.841,0.508,4.248
	c0.338,1.408,0.89,2.663,1.656,3.767c0.766,1.105,1.764,2.004,2.993,2.699c1.229,0.695,2.733,1.042,4.516,1.042
	c1.603,0,2.974-0.347,4.114-1.042c1.14-0.695,2.075-1.594,2.806-2.699c0.729-1.104,1.264-2.359,1.603-3.767
	c0.338-1.407,0.508-2.823,0.508-4.248S25.466,23.481,25.128,22.073"
        />
        <rect
          stroke="#130f30"
          strokeWidth=".1"
          x="34.506"
          y="34.177"
          fill="#505050"
          width="5.932"
          height="5.932"
        />
        <polygon
          fill={props.logoColor}
          points="55.775,1.954 55.775,22.42 65.34,12.48 74.318,12.48 63.898,22.635 75.494,40.108 66.302,40.108 
	58.714,27.765 55.775,30.597 55.775,40.108 48.188,40.108 48.188,1.954 "
        />
        <path
          fill={props.logoColor}
          d="M82.57,33.483c1.14,1.104,2.779,1.656,4.916,1.656c1.532,0,2.85-0.382,3.955-1.149
	c1.104-0.765,1.781-1.576,2.031-2.431h6.679c-1.068,3.313-2.708,5.683-4.916,7.107c-2.209,1.426-4.881,2.138-8.016,2.138
	c-2.173,0-4.133-0.348-5.878-1.042c-1.746-0.695-3.225-1.684-4.436-2.966c-1.211-1.283-2.146-2.814-2.805-4.596
	c-0.66-1.781-0.989-3.741-0.989-5.878c0-2.066,0.338-3.99,1.015-5.771c0.677-1.782,1.639-3.322,2.886-4.623
	c1.247-1.3,2.734-2.325,4.463-3.073c1.727-0.748,3.642-1.122,5.744-1.122c2.351,0,4.399,0.454,6.146,1.362
	c1.745,0.909,3.18,2.13,4.303,3.661c1.121,1.532,1.931,3.278,2.431,5.237c0.498,1.96,0.676,4.008,0.534,6.146H80.701
	C80.808,30.597,81.43,32.379,82.57,33.483 M91.147,18.947c-0.909-0.997-2.29-1.496-4.142-1.496c-1.211,0-2.217,0.205-3.019,0.615
	c-0.801,0.409-1.443,0.917-1.923,1.523c-0.481,0.606-0.821,1.247-1.016,1.923c-0.196,0.678-0.312,1.283-0.347,1.817h12.344
	C92.688,21.405,92.056,19.945,91.147,18.947"
        />
        <polygon
          fill={props.logoColor}
          points="109.321,40.109 99.862,12.481 107.824,12.481 113.649,31.345 113.756,31.345 119.581,12.481 
	127.116,12.481 117.765,40.109 "
        />
        <path
          fill={props.logoColor}
          d="M129.328,1.954h7.589v6.252h-7.589V1.954z M136.917,40.109h-7.589V12.481h7.589V40.109z"
        />
        <path
          fill={props.logoColor}
          d="M147.45,12.481v3.848h0.16c0.962-1.604,2.209-2.77,3.741-3.501c1.531-0.729,3.1-1.095,4.702-1.095
	c2.031,0,3.696,0.276,4.997,0.828c1.3,0.553,2.324,1.318,3.072,2.298s1.273,2.173,1.577,3.58c0.302,1.408,0.454,2.966,0.454,4.676
	v16.994h-7.589V24.505c0-2.28-0.356-3.982-1.068-5.104c-0.713-1.122-1.978-1.683-3.794-1.683c-2.067,0-3.563,0.615-4.489,1.844
	c-0.927,1.229-1.39,3.251-1.39,6.065v14.482h-7.588V12.481H147.45z"
        />
        <path
          fill={props.logoColor}
          d="M20.799,58.172c1.621,0.784,2.984,1.835,4.088,3.152c1.104,1.318,1.941,2.859,2.512,4.623
	c0.57,1.764,0.855,3.643,0.855,5.638c0,1.996-0.285,3.874-0.855,5.638c-0.571,1.764-1.408,3.305-2.512,4.622
	c-1.104,1.319-2.467,2.361-4.088,3.126c-1.621,0.766-3.483,1.148-5.584,1.148c-2.103,0-3.964-0.383-5.585-1.148
	c-1.621-0.765-2.984-1.807-4.088-3.126c-1.104-1.317-1.942-2.858-2.511-4.622c-0.571-1.764-0.855-3.642-0.855-5.638
	c0-1.995,0.284-3.874,0.855-5.638c0.569-1.764,1.407-3.305,2.511-4.623c1.104-1.317,2.467-2.368,4.088-3.152
	c1.621-0.784,3.482-1.176,5.585-1.176C17.316,56.996,19.178,57.388,20.799,58.172 M10.993,60.844
	c-1.211,0.678-2.218,1.568-3.019,2.672c-0.802,1.104-1.408,2.36-1.817,3.768c-0.41,1.408-0.615,2.842-0.615,4.302
	c0,1.461,0.205,2.896,0.615,4.302c0.409,1.408,1.015,2.664,1.817,3.768c0.801,1.104,1.808,1.996,3.019,2.672
	c1.211,0.678,2.619,1.016,4.222,1.016s3.01-0.338,4.221-1.016c1.211-0.676,2.218-1.567,3.02-2.672
	c0.801-1.104,1.407-2.359,1.817-3.768c0.409-1.406,0.614-2.841,0.614-4.302c0-1.46-0.205-2.894-0.614-4.302
	c-0.41-1.407-1.016-2.664-1.817-3.768c-0.802-1.104-1.809-1.994-3.02-2.672c-1.211-0.677-2.618-1.016-4.221-1.016
	S12.204,60.167,10.993,60.844"
        />
        <path
          stroke="#130f30"
          strokeWidth=".1"
          fill="#505050"
          d="M40.438,47.217v5.932c0,1.068-0.116,2.075-0.347,3.02c-0.233,0.943-0.588,1.773-1.069,2.484
	c-0.481,0.713-1.087,1.282-1.817,1.711c-0.731,0.427-1.612,0.641-2.645,0.641v-2.672c0.605,0,1.104-0.151,1.496-0.454
	c0.392-0.304,0.712-0.703,0.962-1.203c0.249-0.498,0.418-1.06,0.507-1.683s0.134-1.237,0.134-1.844h-3.153v-5.932H40.438z"
        />
        <rect x="48.187" y="47.217" fill={props.logoColor} width="3.367" height="38.155" />
        <path
          fill={props.logoColor}
          d="M60.617,76.261c0.348,1.301,0.908,2.467,1.678,3.5c0.769,1.034,1.74,1.889,2.914,2.565
	c1.172,0.678,2.585,1.016,4.234,1.016c2.53,0,4.51-0.641,5.939-1.924c1.431-1.282,2.42-2.993,2.97-5.131h3.465
	c-0.734,3.136-2.082,5.559-4.042,7.269c-1.962,1.71-4.739,2.563-8.332,2.563c-2.236,0-4.17-0.383-5.801-1.147
	c-1.632-0.767-2.962-1.817-3.988-3.153s-1.787-2.886-2.281-4.65c-0.495-1.762-0.743-3.623-0.743-5.584
	c0-1.816,0.248-3.598,0.743-5.344c0.494-1.744,1.255-3.304,2.281-4.676c1.026-1.371,2.356-2.475,3.988-3.312
	c1.631-0.838,3.565-1.256,5.801-1.256c2.273,0,4.216,0.445,5.83,1.336c1.612,0.891,2.923,2.058,3.932,3.5
	c1.008,1.442,1.732,3.1,2.172,4.97c0.44,1.871,0.623,3.769,0.55,5.692H60.094C60.094,73.705,60.268,74.961,60.617,76.261
	 M77.775,65.974c-0.422-1.176-1.018-2.218-1.788-3.126c-0.769-0.909-1.704-1.639-2.804-2.191c-1.1-0.552-2.347-0.828-3.74-0.828
	c-1.429,0-2.694,0.276-3.794,0.828c-1.1,0.553-2.035,1.282-2.805,2.191c-0.77,0.908-1.384,1.959-1.842,3.152
	c-0.459,1.195-0.761,2.414-0.908,3.662h18.368C78.425,68.379,78.196,67.149,77.775,65.974"
        />
        <path
          fill={props.logoColor}
          d="M87.732,62.073c0.586-1.177,1.385-2.138,2.393-2.886s2.19-1.301,3.547-1.657
	c1.357-0.354,2.859-0.534,4.51-0.534c1.246,0,2.492,0.116,3.739,0.348c1.246,0.231,2.365,0.668,3.354,1.309
	c0.99,0.642,1.796,1.542,2.42,2.699c0.623,1.158,0.936,2.662,0.936,4.515V80.51c0,1.056,0.81,1.976,1.864,2.025
	c0.471,0.023,0.894-0.047,1.27-0.209v2.832c-0.403,0.072-0.761,0.125-1.072,0.161c-0.312,0.034-0.706,0.053-1.183,0.053
	c-0.879,0-1.586-0.114-2.117-0.347s-0.944-0.562-1.237-0.989c-0.294-0.428-0.486-0.935-0.577-1.522
	c-0.092-0.588-0.138-1.238-0.138-1.951h-0.109c-0.624,0.891-1.257,1.684-1.897,2.378c-0.642,0.694-1.356,1.274-2.145,1.737
	c-0.789,0.463-1.688,0.818-2.695,1.068c-1.008,0.249-2.209,0.374-3.602,0.374c-1.32,0-2.558-0.151-3.713-0.454
	c-1.154-0.302-2.162-0.783-3.023-1.443c-0.862-0.658-1.54-1.496-2.035-2.512c-0.495-1.015-0.743-2.218-0.743-3.607
	c0-1.924,0.44-3.428,1.32-4.514c0.88-1.088,2.043-1.916,3.491-2.486c1.449-0.569,3.08-0.969,4.896-1.202
	c1.815-0.231,3.657-0.454,5.526-0.668c0.733-0.071,1.375-0.16,1.925-0.267c0.55-0.107,1.008-0.295,1.375-0.562
	c0.366-0.267,0.65-0.632,0.853-1.097c0.201-0.461,0.303-1.068,0.303-1.816c0-1.139-0.192-2.074-0.577-2.805
	c-0.386-0.73-0.918-1.309-1.597-1.737c-0.678-0.427-1.466-0.722-2.363-0.881c-0.899-0.161-1.861-0.241-2.887-0.241
	c-2.201,0-3.998,0.508-5.39,1.523c-1.394,1.015-2.127,2.645-2.2,4.889h-3.465C86.798,64.637,87.146,63.248,87.732,62.073
	 M105.056,70.303c-0.22,0.393-0.642,0.676-1.265,0.854c-0.624,0.179-1.174,0.303-1.65,0.374c-1.467,0.25-2.979,0.473-4.537,0.668
	c-1.558,0.195-2.979,0.49-4.261,0.882c-1.284,0.392-2.338,0.953-3.162,1.683c-0.826,0.731-1.237,1.773-1.237,3.127
	c0,0.854,0.174,1.613,0.522,2.271c0.347,0.66,0.815,1.229,1.401,1.71s1.266,0.848,2.035,1.096c0.77,0.25,1.557,0.375,2.364,0.375
	c1.32,0,2.585-0.195,3.795-0.588c1.21-0.392,2.265-0.963,3.162-1.711c0.898-0.748,1.612-1.656,2.146-2.725
	c0.53-1.069,0.797-2.28,0.797-3.635v-4.381H105.056z"
        />
        <path
          fill={props.logoColor}
          d="M119.007,57.412v6.852h0.11c0.88-2.245,2.244-3.973,4.097-5.184c1.851-1.211,4.061-1.764,6.627-1.656v3.365
	c-1.576-0.069-3.007,0.135-4.289,0.615c-1.284,0.481-2.393,1.168-3.327,2.057c-0.936,0.892-1.659,1.951-2.173,3.181
	s-0.77,2.574-0.77,4.035v14.694h-3.465V57.412H119.007z"
        />
        <path
          fill={props.logoColor}
          d="M136.102,57.412l9.505,23.898l8.9-23.898h3.465l-12.474,32.129c-0.516,1.175-0.991,2.145-1.431,2.912
	c-0.44,0.766-0.927,1.371-1.458,1.816s-1.154,0.766-1.87,0.962c-0.715,0.195-1.622,0.294-2.722,0.294
	c-0.697-0.035-1.237-0.062-1.622-0.08c-0.385-0.019-0.725-0.08-1.018-0.187v-2.832c0.403,0.069,0.797,0.133,1.183,0.187
	c0.385,0.053,0.778,0.08,1.183,0.08c0.77,0,1.401-0.107,1.896-0.32c0.495-0.215,0.926-0.508,1.292-0.883
	c0.367-0.373,0.679-0.828,0.936-1.363c0.256-0.533,0.531-1.121,0.824-1.762l1.21-3.1l-11.484-27.854H136.102z"
        />
      </g>
    </svg>
  );
}

export default Logo;
