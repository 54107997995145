import React from 'react';

const EmailIcon = () => {
  const imageStyle = {
    minWidth: '5vh',
    height: '67px',
    margin: '0 1.8vw 0 0',
  };

  return (
    <a href="mailto: kevin@kevinoleary.net" title="Email">
    <svg style={imageStyle} viewBox="0 0 32 32">
        <g transform="scale(1)">
          <g>
            <path
              d="M16,0C7.163,0,0,7.163,0,16c0,8.836,7.163,16,16,16s16-7.164,16-16C32,7.163,24.837,0,16,0z"
              fill="rgba(9, 100, 100, 0.4)"
            />
          </g>
          <g>
            <polygon fill="#FFFFFF" points="6.518,21.815 11.707,15.291 6.518,12.119" />
            <polygon
              fill="#FFFFFF"
              points="19.5,15.746 15.989,17.908 12.472,15.758 7.11,22.5 24.867,22.5   "
            />
            <polygon
              fill="#FFFFFF"
              points="15.988,16.864 25.482,11.017 25.482,9.5 6.518,9.5 6.518,11.076   "
            />
            <polygon fill="#FFFFFF" points="20.263,15.276 25.482,21.843 25.482,12.062" />
          </g>
        </g>
      
    </svg>
    </a>
  );
};

export default EmailIcon;
